<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Marzo 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div>
        <span class="day" v-if="shouldDisplayDay" :style="{ fontWeight: 'bold' }">
            {{ displayDays }}
            <span v-if="shouldDisplayDay"> d</span>
        </span>
        <span class="sepr" v-if="shouldDisplayDay" :style="{ fontWeight: 'bold' }">:</span>

        <span class="hour" v-if="shouldDisplayHour" :style="{ fontWeight: 'bold' }">
            {{ displayHours }}
            <span v-if="shouldDisplayHour"> h</span>
        </span>
        <span class="sepr" v-if="shouldDisplayHour" :style="{ fontWeight: 'bold' }">:</span>

        <span class="minute" v-if="shouldDisplayMinute" :style="{ fontWeight: 'bold' }">
            {{ displayMins }}
            <span v-if="shouldDisplayMinute"> m</span>
        </span>
        <span class="sepr" v-if="shouldDisplayMinute" :style="{ fontWeight: 'bold' }">:</span>

        <span class="second" v-if="shouldDisplaySecond" :style="{ fontWeight: 'bold' }">
            {{ displaySecs }}
            <span v-if="shouldDisplaySecond"> s</span>
        </span>
    </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
export default {
    props: {
        classWrapper: {
            type: String,
            default: ""
        },
        fechaSolicitud: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            timerSecs: 0,
            fechaInicio: null,
        };
    },
    computed: {
        shouldDisplayDay() {
            return this.timerSecs >= 86400; // 86400 segundos en un día
        },
        displayDays() {
            return this.addPadZero(Math.floor(this.timerSecs / 86400));
        },
        shouldDisplayHour() {
            return this.timerSecs >= 3600; // 3600 segundos en una hora
        },
        displayHours() {
            return this.addPadZero(Math.floor((this.timerSecs % 86400) / 3600));
        },
        shouldDisplayMinute() {
            return this.timerSecs >= 60; // 60 segundos en un minuto
        },
        displayMins() {
            return this.addPadZero(Math.floor((this.timerSecs % 3600) / 60));
        },
        shouldDisplaySecond() {
            return true;
        },
        displaySecs() {
            return this.addPadZero(this.timerSecs % 60);
        }
    },
    watch: {
        // Reiniciar la fecha de inicio y el temporizador cuando la fecha de solicitud cambie
        fechaSolicitud(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fechaInicio = new Date(newValue);
                this.timerSecs = 0; 
                this.start(); 
            }
        }
    },
    methods: {
        /** Agrega cero delante de un valor numérico si es menor que 10.
         * 
         * @param {number} val - El valor numérico a formatear.
         * @returns {string} - El valor formateado como una cadena.
         */
        addPadZero(val) {
            return val < 10 ? `0${val}` : `${val}`;
        },
        /**
         * Inicia el temporizador para calcular el tiempo transcurrido.
         */
        start() {
            this.stop();
            // Convierte la fecha de solicitud a un objeto Date
            this.fechaInicio = new Date(this.fechaSolicitud);
            // Actualiza el temporizador cada segundo
            this.intervalo = setInterval(() => {
                // Obtiene la fecha actual
                const fechaActual = new Date();
                // Calcula la diferencia de tiempo en segundos
                const diferencia = Math.floor((fechaActual.getTime() - this.fechaInicio.getTime()) / 1000);
                // Actualiza el estado del componente con la diferencia de tiempo en segundos
                this.timerSecs = diferencia;
            }, 1000);
        },
        /** Detiene el temporizador si está en funcionamiento. */
        stop() {
            if (this.intervalo) {
                clearInterval(this.intervalo);
                this.intervalo = null;
            }
        },
        /** Reinicia el temporizador. */
        reset() {
            // Detener el temporizador antes de reiniciar
            this.stop();
            
            // Reiniciar el temporizador
            this.timerSecs = 0;
            this.start();
        }
    },
    /**
     * Ejecuta la función start() al crear el componente para iniciar el temporizador.
     */
    created() {
      this.start();
    }
};
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
}
.sepr {
    margin: 0 0.5rem;
}
.time {
    font-weight: bold;
    display: flex;
    justify-content: center;
}
.separadores {
    padding: 0 4px;
    font-weight: bold;
    display: flex;
    align-content: flex-start;
}
</style>
  